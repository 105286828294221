/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import icon from "./images/icon.svg"

type Props = {}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  border: 2px dashed rgba(255, 255, 255, 0.75);
  padding: 1.5rem;
  border-radius: 8px;
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    object-position: center center;
  }
  p {
    font-size: 1rem;
    margin: 0 2rem;
    font-family: "AvenirLTStdMedium";
  }
  a {
    font-size: 1rem;
    position: relative;
    z-index: 1;
    font-family: "AvenirLTStdBlack";
    color: rgba(255, 255, 255, 0.7);
    background-color: transparent;
    display: inline-table;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    &:before {
      content: "";
      top: 0;
      left: 0;
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      transform: rotate(-2deg);
    }
  }
`

const DownloadField = ({ ...props }: Props) => {
  return (
    <Container {...props}>
      <img src={icon} alt="Sound Idea Sessions" />
      <p>Growth Hacker Starter Pack</p>
      <a
        href="https://www.soundideasessions.com/Growth_Hacker_Starter_Pack.zip"
        className="mouse-link"
        target="_blank"
        rel="noreferrer noopener"
        download
      >
        Download
      </a>
    </Container>
  )
}

export default DownloadField
