/**
 * @flow
 */

import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import ReviewPage from "../components/Auth/ReviewPage"

type Props = {
  location: Object,
}

const Review = ({ location }: Props) => {
  const menuItems = [
    // { name: "Profile", path: "/profile" },
    { name: "Leaderboard", path: "/leaderboard" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Review" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <ReviewPage location={location} />
      <Footer back />
    </>
  )
}

export default Review
