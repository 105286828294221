/**
 * @flow
 */
import React, { useState } from "react"
import styled from "styled-components"

import media from "../../../styles/media"
import PageHeader from "../../elements/PageHeader"
import DownloadField from "../../elements/DownloadField"

type Props = {
  location: Object,
}

const Container = styled.div`
  &.red {
    background-color: rgba(196, 18, 48, 0.99);
  }
  &.black {
    background-color: rgba(63, 63, 63, 0.99);
  }
  &.p-left {
    padding-left: var(--gutter-s);
  }
  &.p-left-l {
    padding-left: var(--gutter-l);
  }
  &.p-right {
    padding-right: var(--gutter-s);
  }
  &.p-top,
  &.p-bottom {
    padding-bottom: 10vh;
  }
  ${media.tablet`
    &.p-left {
      padding-left: 10vw;
    }
    &.p-left-l {
      padding-left: 10vw;
    }
    &.p-right {
      padding-right: 5vw;
    }
    &.p-top,
    &.p-bottom {
      padding-bottom: 15vh;
    }
  `}
  ${media.laptop`
    &.p-left {
      padding-left: 25vw;
    }
    &.p-left-l {
      padding-left: 25vw;
    }
    &.p-right {
      padding-right: 20vw;
    }
  `}
`

const Intro = styled.div`
  margin-bottom: 2rem;
  position: relative;
  padding-top: 60px;
  h1,
  p {
    color: rgba(255, 255, 255, 0.8);
  }
  h1 {
    font-family: "AvenirLTStdBlack";
    font-size: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%) rotate(-2deg);
  }
  p {
    font-family: "butlermedium";
    line-height: 1.75rem;
    a {
      text-decoration: underline;
    }
  }
  ${media.laptop`
    padding-top: 80px;
    h1 {
      font-size: 8rem;
    }
  `}
`

const ReviewPage = ({ location }: Props) => {
  const renderIntro = () => {
    if (location.state.status === "NEW") {
      return (
        <p>
          You have successfully signed up and your profile is currently being
          reviewed. Please note that this could take up to 24 hours. In the mean
          time you can download the Growth Hacker Starter Pack below.
        </p>
      )
    } else {
      return (
        <p>
          You have successfully updated your Growth Hacker Profile. Please note
          that updating your profile may be subject to re-approval which could
          take up to 24 hours before your changes will reflect.
        </p>
      )
    }
  }

  return (
    <>
      <Container className="red p-left-l p-bottom">
        <PageHeader />
      </Container>
      <Container className="black p-left p-right p-bottom">
        <Intro>
          <h1>Review</h1>
          {location.state && renderIntro()}
        </Intro>
        <DownloadField />
      </Container>
    </>
  )
}

export default ReviewPage
